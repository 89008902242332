body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.shimmer {
  background: linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%);
  background-size: 200% 100%;
  animation: shimmer 1.5s infinite;
}

@keyframes shimmer {
  0% {
      background-position: 200% 0;
  }
  100% {
      background-position: -200% 0;
  }
}

.shimmer-image {
  width: 250px;
  height: 200px;
  border-radius: 10px;
}


.shimmer-image-small {
  width: 100px;
  height: 100px;
  border-radius: 50%;
}

.shimmer-text {
  width: 250px;
  height: 20px;
  margin: 10px 0;
}

.shimmer-icons {
  width: 120px;
  height: 20px;
  margin-top: 15px;
}

@media (max-width:760px){
  .shimmer-image {
    width: 100%;
  }
  
  .shimmer-text {
    width: 100%;
  }
  
  .shimmer-icons {
    width: 150px;
    height: 20px;
  }
  
}
